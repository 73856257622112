import React, {
  createContext, FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { PROFILE, ProfileOutput } from './api/profile';

interface GlobalContextInterface {
  executeAfterFetchProfile: (fn: () => void | Promise<void>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGlobalProfile: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  profile: { data?: ProfileOutput; loading: boolean, called: boolean };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchProfile: (force?: any) => void;
  block?: boolean;
  handleBlock: (value: boolean) => void;
}

const defaultData = {
  executeAfterFetchProfile: () => undefined,
  getGlobalProfile: () => undefined,
  profile: { data: undefined, loading: false, called: false },
  refetchProfile: () => undefined,
  handleBlock: () => undefined,
};

export const GlobalContext = createContext<GlobalContextInterface>(defaultData);

export const GlobalStorage: FC = ({ children }) => {
  const queue = useRef<(() => void | Promise<void>)[]>([]);
  const [block, setBlock] = useState(false);

  const executeAfterFetchProfile = useCallback((fn: () => void | Promise<void>) => {
    queue.current.push(fn);
  }, []);

  const [getGlobalProfile, {
    error: profileError, data, loading, called,
  }] = useLazyQuery<ProfileOutput>(PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d) {
        while (queue.current.length) {
          const fn = queue.current.shift();
          if (fn) {
            fn();
          }
        }
      }
    },
  });

  const refetchProfile = useCallback((force = false) => {
    if ((!called && !loading) || force) {
      getGlobalProfile();
    }
  }, [called, getGlobalProfile, loading]);

  useEffect(() => {
    if (data?.profile._id && data?.profile.courses[0].course) {
      try {
        window.StonlyWidget(
          'identify',
          data?.profile._id,
          {
            userid: data?.profile._id,
            createdat: new Date(),
            courseid: data?.profile.courses[0].course,
          },
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, [data?.profile]);

  useEffect(() => {
    if ((profileError)) {
      getGlobalProfile();
    }
  }, [profileError, getGlobalProfile]);

  return (
    <GlobalContext.Provider
      value={{
        executeAfterFetchProfile,
        getGlobalProfile,
        profile: { data, loading, called },
        refetchProfile,
        block,
        handleBlock: (value: boolean) => {
          setBlock(value);
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
