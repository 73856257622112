import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopCircle } from '@fortawesome/free-regular-svg-icons';
import { useDevice } from '../../../hooks/useDevice';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

type FinishButtonType = {
  modelName: string;
  onClick: () => void;
  show?: boolean;
  device?: 'mobile' | 'web' | 'both';
  isLoading?: boolean;
  isBlinking?: boolean;
  disabled?: boolean;
  mb?: number;
  mt?: number;
  label?: string;
}

export const FinishButton: FC<FinishButtonType> = ({
  modelName,
  isBlinking,
  onClick,
  show = true,
  device = 'both',
  isLoading = false,
  disabled = false,
  mb = 0,
  mt = 2,
  label,
}: FinishButtonType) => {
  const currentDevice = useDevice();
  if ((device !== 'both' && device !== currentDevice) || !show) {
    return <></>;
  }
  return (
    <Flex>
      <PrimaryButton
        flex={1}
        className={isBlinking ? 'orange-blink-animation' : ''}
        m={mt ?? 2}
        mb={mb}
        px={modelName === '' ? 2 : 0}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        onClick={onClick}
      >
        {label || `Finalizar ${modelName}`}
      </PrimaryButton>
    </Flex>
  );
};

export const FinishButtonV2: FC<FinishButtonType> = ({
  modelName,
  isBlinking,
  onClick,
  show = true,
  device = 'both',
  isLoading = false,
  disabled = false,
  mb = 0,
  mt = 2,
  label,
}) => {
  const currentDevice = useDevice();
  if ((device !== 'both' && device !== currentDevice) || !show) {
    return <></>;
  }
  return (
    <Flex>
      <PrimaryButton
        maxWidth="400px"
        flex={1}
        className={isBlinking ? 'orange-blink-animation' : ''}
        mb={mb}
        px={modelName === '' ? 2 : 2}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        onClick={onClick}
        backgroundColor="#415F91"
        borderRadius="50px"
      >
        <FontAwesomeIcon color="white" icon={faStopCircle} />
        <Text fontSize="14px" marginLeft="4px" color="white">{label || `finalizar ${modelName}`}</Text>
      </PrimaryButton>
    </Flex>
  );
};
