import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  Box, Text, Input, Button, Flex, HStack, Select, VStack, Checkbox,
  Drawer, useDisclosure, DrawerOverlay, DrawerContent, DrawerHeader, IconButton, DrawerBody, useTheme,
  Link, Center,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link as RRDLink, useHistory } from 'react-router-dom';
import { useCoursesMany } from '../../api/courses';
import { PasswordAristo } from './_components/PasswordAristo';
import { toast } from '../../utils/toast';
import { useCreateAccount } from '../../api/create-account';

const RegisterForm: FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [userCourse, setUserCourse] = useState('');
  const [loading, setLoading] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const theme = useTheme();
  const [password1HasError, setPassword1HasError] = useState(false);
  const [password2HasError, setPassword2HasError] = useState(false);
  const [emailHasError, setEmailHasError] = useState(false);
  const [nameHasError, setNameHasError] = useState(false);
  const history = useHistory();

  const [createAccountMutation] = useCreateAccount();

  const query = useCoursesMany();

  const courses = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.coursesMany;
    }
    return [];
  }, [query]);

  const handleRegister = useCallback(async e => {
    setLoading(true);
    try {
      if (password1 !== password2) {
        toast.error({
          title: 'Incompatibilidade de senhas!',
          description: 'As senhas nas lacunas "Senha" e "Confirmar senha" não correspondem.',
        });
        setPassword1HasError(true);
        setPassword2HasError(true);
        setLoading(false);
      } else if (
        (name.indexOf(' ') !== -1 && name.split(' ')[0].length > 35)
        || (name.indexOf(' ') === -1 && name.length > 35)) {
        toast.error({
          title: 'Nome incompatível',
          description: `Seu primeiro nome não pode ter mais que 35 caracteres.
          Coloque um apelido caso seu nome seja mesmo grande.`,
        });
        setNameHasError(true);
        setLoading(false);
      } else {
        await createAccountMutation({
          variables: {
            email,
            password: password1,
            name,
            courseId: userCourse,
          },
        });

        toast.success({
          title: 'Conta criada!',
          description: 'Faça login para começar.',
        });
        history.replace('/');
      }
    } catch (error) {
      if (error.message === 'The email address is already in use by another account.') {
        toast.error({
          title: 'E-mail em uso!',
          description: 'Utilize outro e-mail para cadastro.',
        });
        setEmail('');
        setEmailHasError(true);
      }

      if (error.message === 'The password must be a string with at least 6 characters.') {
        toast.error({
          title: 'Senha fraca!',
          description: 'Sua senha deve ter pelo menos 6 caracteres.',
        });
        setPassword1HasError(true);
      }

      if (error.message === 'The email address is improperly formatted.') {
        toast.error({
          title: 'E-mail inválido!',
          description: 'Insira seu endereço de e-mail corretamente.',
        });
        setEmailHasError(true);
      }
      setLoading(false);
    }
  }, [createAccountMutation, email, name, password1, password2, userCourse, history]);

  return (
    <VStack
      align="left"
      justify="space-between"
      color="aristo.900"
      padding={{ base: '2rem', md: '2rem 4rem' }}
      width="100%"
      height={520}
    >
      <Link as={RRDLink} to="/">
        <HStack>
          <FontAwesomeIcon icon={faChevronLeft} />
          <Text color="aristo.900">Voltar</Text>
        </HStack>
      </Link>
      <Box>
        <Text fontSize="3xl" ml="-2px">Bem-vindo!</Text>
        <Text>Faça seu cadastro para continuar.</Text>
      </Box>
      <form>
        <VStack color="aristo.900" spacing="1rem" align="left">

          <Box>
            <Input
              maxLength={70}
              borderColor={nameHasError ? 'red.400' : 'aristo.900'}
              bgColor={nameHasError ? 'red.100' : 'secondary.50'}
              color={nameHasError ? 'red.400' : 'aristo.900'}
              _placeholder={{ color: nameHasError ? 'red.400' : 'light.200' }}
              placeholder="Nome"
              padding="1rem"
              name="name"
              type="text"
              onChange={event => {
                setName(event.target.value);
                setNameHasError(false);
              }}
              value={name}
              required
            />
          </Box>
          <Box>
            <Input
              borderColor={emailHasError ? 'red.400' : 'aristo.900'}
              bgColor={emailHasError ? 'red.100' : 'secondary.50'}
              color={emailHasError ? 'red.400' : 'aristo.900'}
              _placeholder={{ color: emailHasError ? 'red.400' : 'light.200' }}
              placeholder="E-mail"
              padding="1rem"
              label="E-mail"
              name="email"
              type="email"
              onChange={event => {
                setEmail(event.target.value);
                setEmailHasError(false);
              }}
              value={email}
              required
            />
          </Box>
          <Flex gridGap="1rem" flexFlow={{ base: 'column', md: 'row' }}>
            <Box>
              <PasswordAristo
                name="password1"
                borderColor={password1HasError ? 'red.400' : 'aristo.900'}
                bgColor={password1HasError ? 'red.100' : 'secondary.50'}
                color={password1HasError ? 'red.400' : 'aristo.900'}
                _placeholder={{ color: password1HasError ? 'red.400' : 'light.200' }}
                placeholder="Senha"
                adjust={false}
                padding="1rem"
                onChange={e => {
                  setPassword1(e.target.value);
                  setPassword1HasError(false);
                  setPassword2HasError(false);
                }}
                setter={setPassword1}
                getter={password1}
              />
            </Box>
            <Box>
              <PasswordAristo
                name="password2"
                borderColor={password2HasError ? 'red.400' : 'aristo.900'}
                bgColor={password2HasError ? 'red.100' : 'secondary.50'}
                color={password2HasError ? 'red.400' : 'aristo.900'}
                _placeholder={{ color: password2HasError ? 'red.400' : 'light.200' }}
                noOfLines={1}
                adjust={false}
                padding="1rem"
                placeholder="Confirmar senha"
                onChange={e => {
                  setPassword2(e.target.value);
                  setPassword1HasError(false);
                  setPassword2HasError(false);
                }}
                setter={setPassword2}
                getter={password2}
              />
            </Box>
          </Flex>

          <Box>
            {!query.loading ? (
              <Select
                borderColor="aristo.900"
                _placeholder={{ color: 'light.200' }}
                style={{ backgroundColor: theme.colors.secondary[50] }}
                isloading={query.loading ? 'true' : 'false'}
                value={userCourse}
                onChange={e => setUserCourse(e.target.value)}
              >
                <option disabled value="">
                  Escolha seu curso
                </option>
                {courses.map(item => (
                  <option
                    key={item._id}
                    value={item._id}
                  >
                    {item.title}
                  </option>
                ))}
              </Select>
            ) : (
              <Center>
                <FontAwesomeIcon icon={faSpinner} spin />
              </Center>
            )}
          </Box>
        </VStack>
      </form>
      <HStack>
        <Checkbox
          isChecked={termsCheckbox}
          borderColor="aristo.500"
          onChange={() => {
            setTermsCheckbox(prev => !prev);
            console.log(termsCheckbox);
          }}
        >
          Li e aceito os
        </Checkbox>
        <Button
          variant="unstyled"
          _active={{ color: 'aristo.500' }}
          _focus={{ border: 'none' }}
          onClick={onOpen}
          color="aristo.500"
        >
          <u>Termos de uso</u>
        </Button>
      </HStack>
      <Flex justify="center">
        <Button
          flex={1}
          bgColor="aristo.500"
          color="white"
          colorScheme="aristo"
          isLoading={loading}
          onClick={handleRegister}
          disabled={userCourse === '' || !termsCheckbox || loading}
        >
          Cadastrar
        </Button>
      </Flex>
      <Drawer size="md" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent h="100vh" bgColor="white">
          <DrawerHeader>
            <HStack align="center" spacing={4}>
              <IconButton
                aria-label="voltar"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={onClose}
                variant="ghost"
                color="dark.700"
              />
              <Text fontWeight="bold">Termos de uso</Text>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <VStack textAlign="justify" spacing={5} color="aristo.900">
              <Text as="b" mb="2" textAlign="center">
                TERMOS DE USO E POLÍTICAS DE PRIVACIDADE – ARISTO
              </Text>
              <Text>
                Seja bem-vindo(a) a ARISTO!
              </Text>
              <Text>
                Somos uma empresa de educação online e inovação, com o objetivo de
                fornecer sólida preparação e treinamento na área médica e para as
                provas de Residência Médica e Revalidação de diploma, por meio de um método exclusivo de ensino.
                Acreditamos que esse método, inovador e altamente interativo entre
                os nossos professores e os alunos, pode desencadear um processo de
                aprendizagem altamente produtivo e eficaz.
                Primamos pela excelência em nossos conteúdos e por uma relação
                transparente com todos os nossos parceiros, respeitando os
                códigos de ética médica e os regramentos legais atinentes ao nosso
                modelo de negócio.
              </Text>

              <Text as="h1" fontWeight="bold">
                1. CONCEITOS
              </Text>
              <Text>
                <ul>
                  <li>
                    ARISTO: plataforma online de educação e tecnologia estruturada
                    para o oferecimento de cursos na área médica e preparação para
                    concursos de Residência e de Revalidação de diploma.
                  </li>
                  <li>
                    USUÁRIO: aluno adquirente de um dos cursos oferecidos pela
                    plataforma com o objetivo de obter sucesso em suas provas de
                    Residência médica.
                  </li>
                </ul>
              </Text>

              <Text as="h1" fontWeight="bold">
                2. ACEITAÇÃO
              </Text>
              <Text>
                O presente Termo estabelece as obrigações contratadas de livre e
                espontânea vontade, por tempo indeterminado, entre a plataforma e as
                pessoas físicas usuárias da ARISTO.
                Ao utilizar a plataforma, o usuário aceita integralmente as
                presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.
                A aceitação do presente instrumento é imprescindível para o acesso e
                para a utilização de quaisquer serviços fornecidos pela ARISTO.
                Caso não concorde com as disposições deste instrumento, o usuário
                não deve utilizá-los.
              </Text>
              <Text as="h1" fontWeight="bold">
                3. CADASTRAMENTO
              </Text>
              <Text>
                O acesso às funcionalidades da plataforma exigirá a realização de um
                cadastro prévio.
                Ao se cadastrar, o usuário deverá informar dados completos, recentes
                e válidos, sendo de exclusiva responsabilidade dele manter os
                referidos dados atualizados, e também estará se comprometendo com
                a veracidade dos dados fornecidos.
                Além disso, o usuário se compromete a não informar os seus dados
                cadastrais e/ou de acesso à plataforma a terceiros,
                responsabilizando-se integralmente pelo uso que seja feito deles.
                Mediante a realização do cadastro, o usuário declara e
                garante expressamente ser plenamente capaz, podendo exercer e
                usufruir livremente dos serviços e produtos.
                O usuário deverá fornecer um endereço de e-mail válido, através do
                qual o site realizará todas as comunicações necessárias.
                Após a confirmação do cadastro, o usuário possuirá um login e uma
                senha pessoal, a qual assegura ao usuário o acesso individual à
                plataforma. Dessa forma, compete exclusivamente ao usuário a
                manutenção da referida senha de maneira confidencial e segura,
                evitando o acesso indevido às informações pessoais.
                Toda e qualquer atividade realizada com o uso da senha será de
                responsabilidade do usuário, que deverá informar prontamente à
                plataforma em caso de uso indevido da respectiva senha.
                Não será permitido ceder, vender, alugar ou transferir, de qualquer
                forma, a conta, que é pessoal e intransferível.
                O usuário poderá, a qualquer tempo, requerer o cancelamento de seu
                cadastro junto à plataforma. O seu descadastramento será realizado o
                mais rapidamente possível, desde que não haja nenhum óbice legal para tanto.
                O usuário, ao aceitar os Termos e Política de Privacidade, autoriza
                expressamente a plataforma a coletar, usar, armazenar, tratar,
                ceder ou utilizar as informações derivadas do uso dos serviços,
                do site e quaisquer plataformas, incluindo todas as informações
                preenchidas pelo usuário no momento em que realizar ou atualizar
                seu cadastro, além de outras expressamente descritas na Política de
                Privacidade que deverá ser autorizada pelo usuário.
              </Text>

              <Text as="h1" fontWeight="bold">
                4. SERVIÇOS
              </Text>
              <Text>
                A plataforma ARISTO oferece aos usuários os seguintes serviços que,
                a depender do curso e tema, podem ou não estar incluídos:
              </Text>
              <Text as="h2" fontWeight="bold">
                4.1 Extensivo Tradicional e Extensivo Revalida
              </Text>
              <ol>
                <li>
                  Cronograma anual do curso e conteúdo em formato de calendário (planner).
                </li>
                <li>
                  Plataforma digital Aristo Class, com inteligência artificial baseada
                  no desempenho do usuário nas atividades de questões de prova de
                  Residência Médica de anos anteriores.
                </li>
                <li>
                  Conteúdo teórico determinado pela equipe técnica Aristo para compor
                  os principais temas cobrados nas provas de Residência médica,
                  envolvendo cinco grandes áreas de formas desiguais e baseados nas
                  incidências cobradas nas provas do Brasil dos últimos cinco anos,
                  contendo: videoaula, Guia Pareto, flashcards e mapas mentais.
                </li>
                <ul style={{ marginLeft: 48 }}>
                  <li>
                    Videoaula: aulas com duração variada, a depender da necessidade de
                    cada tema, abordando os principais tópicos do assunto. Os vídeos
                    poderão ter dois formatos: videoaula gravada ou aulas em lives
                    (ao vivo), com interação entre aluno e professor em momentos
                    específicos determinados pelo professor no momento da transmissão.
                    A escolha das aulas ao vivo é determinada pela equipe técnica de
                    conteúdo Aristo e pode sofrer alterações de data, horário e local de
                    disponibilização, com aviso ao aluno. É necessário disponibilizar
                    internet para acessar à videoaula e à live, não sendo possível
                    executar o download para assistir offline.
                  </li>
                  <li>
                    Guia Pareto: apostila digital de tamanho variado contendo
                    exposição teórica do conteúdo do tema, com enfoque no conteúdo
                    mais prevalente cobrado nas provas de Residência médica e baseado
                    nas principais literaturas de cada grande área. Pode ser impressa pelo aluno.
                  </li>
                  <li>
                    Flashcards: são os baralhos autorais compostos por perguntas e
                    respostas, com quantidade variada, seguindo o conteúdo abordado no
                    Guia Pareto e contendo uma autoavaliação do acerto pelo usuário
                    no ato de realização do flashcard.
                  </li>
                  <li>
                    Mapas mentais: estrutura em mapa dos tópicos apresentados na
                    videoaula, com formato de mapa mental completo, onde há disposição
                    agrupada de informações, e mapa mental A4, que compreende o mapa
                    exposto pelo professor durante a apresentação na videoaula. Ambos os
                    mapas podem ser impressos pelo aluno.
                  </li>
                </ul>
                <li>
                  Lives de metodologia: lives realizadas via plataforma específica para
                  tal, com envio de link prévio aos alunos, nas quais são abordadas
                  estratégias para melhora de desempenho nos estudos e técnicas de
                  preparação para a prova de Residência médica.
                </li>
                <li>
                  Banco de questões formado por provas de Residência médica e
                  Revalidação médica de anos anteriores, de diversificadas instituições
                  aplicadoras de provas no Brasil.
                </li>
                <li>
                  Suporte
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Suporte de conteúdo: é realizado por meio do fórum presente na
                      plataforma, com possibilidade de escrever dúvida em questões e
                      nos conteúdos teóricos, obtendo resposta por equipe técnica de
                      médicos e plantonistas internos de Medicina, com prazo de 48 horas
                      úteis.
                    </li>
                    <li>
                      Suporte de dúvidas gerais: é realizado por meio do WhatsApp,
                      por conta comercial e pelo mentor do aluno, pessoa destinada a
                      manter o contato direto com o aluno e solucionar as dúvidas
                      sobre usabilidade, cronogramas do curso e metodologia.
                    </li>
                    <li>
                      +Aristo: página na plataforma com disponibilização de cursos
                      bônus e gravações das lives.
                    </li>
                  </ul>
                </li>
              </ol>

              <Text as="h2" fontWeight="bold">
                4.2 Extensivo Programado e Extensivo Revalida Programado
              </Text>
              <ol>
                <li>
                  Cronograma anual do curso e conteúdo em formato de calendário (planner).
                </li>
                <li>
                  Plataforma digital Aristo Class, com inteligência artificial baseada
                  no desempenho do usuário nas atividades de questões de prova de
                  Residência Médica de anos anteriores.
                </li>
                <li>
                  Conteúdo teórico determinado pela equipe técnica Aristo para
                  compor os principais temas cobrados nas provas de Residência
                  médica, envolvendo cinco grandes áreas de formas desiguais e
                  baseados nas incidências cobradas nas provas do Brasil dos
                  últimos cinco anos, contendo: videoaula, Guia Pareto,
                  flashcards e mapas mentais.
                </li>
                <ul style={{ marginLeft: 48 }}>
                  <li>
                    Videoaula: aulas gravadas com duração variada, a depender da
                    necessidade de cada tema, abordando os principais tópicos dos
                    assuntos. É necessário disponibilizar internet para acessar à
                    videoaula e à live, não sendo possível executar o download para
                    assistir offline.
                  </li>
                  <li>
                    Guia Pareto: apostila digital de tamanho variado contendo
                    exposição teórica do conteúdo do tema, com enfoque no conteúdo
                    mais prevalente cobrado nas provas de Residência médica e
                    baseado nas principais literaturas de cada grande área.
                    Pode ser impressa pelo aluno.
                  </li>
                  <li>
                    Flashcards: são os baralhos autorais compostos por perguntas e
                    respostas, com quantidade variada, seguindo o conteúdo abordado
                    no Guia Pareto e contendo uma autoavaliação do acerto pelo
                    usuário no ato de realização do flashcard.
                  </li>
                  <li>
                    Mapas mentais: estrutura em mapa dos tópicos apresentados na
                    videoaula, com formato de mapa mental completo,
                    onde há disposição agrupada de informações, e mapa mental A4,
                    que compreende o mapa exposto pelo professor durante a
                    apresentação na videoaula. Ambos os mapas podem ser impressos
                    pelo aluno.
                  </li>
                </ul>
                <li>
                  Lives de metodologia: lives realizadas via plataforma
                  específica para tal, com envio de link prévio aos alunos,
                  nas quais são abordadas estratégias para melhora de desempenho
                  nos estudos e técnicas de preparação para a prova de Residência
                  médica.
                </li>
                <li>
                  Banco de questões formado por provas de Residência médica e
                  revalidação médica de anos anteriores, de diversificadas instituições
                  aplicadoras de provas no Brasil.
                </li>
                <li>
                  Suporte
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Suporte de conteúdo: é realizado por meio do fórum presente na
                      plataforma, com possibilidade de escrever dúvida em questões
                      e nos conteúdos teóricos, obtendo resposta por equipe técnica
                      de médicos e plantonistas internos de Medicina, com prazo de 48
                      horas úteis.
                    </li>
                    <li>
                      Suporte de dúvidas gerais: é realizado por meio do WhatsApp, por
                      conta comercial e pelo mentor do aluno, pessoa destinada a manter o
                      contato direto com o aluno e solucionar as dúvidas sobre usabilidade,
                      cronogramas do curso e metodologia.
                    </li>
                  </ul>
                </li>
                <li>
                  +Aristo: página na plataforma com disponibilização de
                  cursos bônus e gravações das lives.
                </li>
              </ol>
              <Text as="h2" fontWeight="bold">
                4.3 Cursos Intensivos
              </Text>
              <ol>
                <li>
                  Cronograma do curso e conteúdo em formato de calendário (planner).
                </li>
                <li>
                  Plataforma digital Aristo Class, com inteligência artificial baseada
                  no desempenho do usuário nas atividades de questões de prova de
                  Residência Médica de anos anteriores.
                </li>
                <li>
                  Conteúdo teórico determinado pela equipe técnica Aristo para compor
                  os principais temas cobrados nas provas de Residência médica,
                  com incidência mínima de 0,5% nas provas do curso em questão,
                  distribuídos pelas cinco grandes áreas de formas desiguais e
                  baseados nas incidências cobradas nas provas do Brasil dos últimos
                  cinco anos, contendo: videoaula, Guia Pareto ou caderno de
                  questões, flashcards e mapas mentais ou slides.
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Videoaula: aulas com duração variada, a depender da
                      necessidade de cada tema, abordando os principais tópicos do
                      assunto. Os vídeos poderão ter dois formatos: videoaula
                      gravada ou aulas em lives (ao vivo), com interação entre
                      aluno e professor em momentos específicos determinados pelo
                      professor no momento da transmissão. A escolha das aulas
                      ao vivo é determinada pela equipe técnica de conteúdo
                      Aristo e pode sofrer alterações de data, horário e local
                      de disponibilização, com aviso ao aluno.
                      É necessário disponibilizar internet para acessar à
                      videoaula e à live, não sendo possível executar o download
                      para assistir offline.
                    </li>
                    <li>
                      Guia Pareto: apostila digital de tamanho variado
                      contendo exposição teórica do conteúdo do tema, com enfoque
                      no conteúdo mais prevalente cobrado nas provas de
                      Residência médica e baseado nas principais literaturas de
                      cada grande área. Pode ser impressa pelo aluno.
                    </li>
                    <li>
                      Flashcards: são os baralhos autorais compostos por perguntas
                      e respostas, com quantidade variada, seguindo o conteúdo
                      abordado no Guia Pareto e contendo uma autoavaliação
                      do acerto pelo usuário no ato de realização do flashcard.
                    </li>
                    <li>
                      Mapas mentais ou slides: estrutura em mapa dos tópicos apresentados
                      na videoaula, com formato de mapa mental completo, onde há
                      disposição agrupada de informações, e mapa mental A4, que
                      compreende o mapa exposto pelo professor durante a apresentação
                      na videoaula. Em determinados cursos da modalidade Intensiva também
                      poderão ser utilizados slides nas aulas no lugar dos mapas mentais.
                      Os slides são agrupados em documento único, em formato PDF e
                      disponibilizados no mesmo campo destinado aos mapas mentais.
                      Ambos, mapas e slides, podem ser impressos pelo aluno.
                    </li>
                  </ul>
                </li>
                <li>
                  Banco de questões formado por provas de Residência médica e
                  revalidação médica de anos anteriores, de diversificadas
                  instituições aplicadoras de provas no Brasil.
                </li>
                <li>
                  Suporte
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Suporte de conteúdo: é realizado por meio do fórum presente na
                      plataforma, com possibilidade de escrever dúvida em questões e nos
                      conteúdos teóricos, obtendo resposta por equipe técnica de médicos
                      e plantonistas internos de Medicina, com prazo de 48 horas úteis.
                    </li>
                    <li>
                      Suporte de dúvidas gerais: é realizado por meio do WhatsApp, por conta
                      comercial e pelo mentor do aluno, pessoa destinada a manter o contato
                      direto com o aluno e solucionar as dúvidas sobre usabilidade,
                      cronogramas do curso e metodologia.
                    </li>
                  </ul>
                </li>
              </ol>
              <Text as="h2" fontWeight="bold">
                4.4 Cursos Off Residência
              </Text>
              <ol>
                <li>
                  Plataforma digital Aristo Class, com inteligência artificial baseada
                  no desempenho do usuário nas atividades de questões de prova de
                  Residência Médica de anos anteriores.
                </li>
                <li>
                  Conteúdo teórico determinado pela equipe técnica Aristo,
                  composto pelos temas necessários e relevantes de acordo com
                  cada curso, contendo: videoaula (variável a depender do curso),
                  Guia Pareto, flashcards e slides (variável a depender do curso).
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Videoaula: aulas com duração variada, a depender da necessidade de cada
                      tema, abordando os principais tópicos do assunto de acordo com
                      cronograma. Os vídeos são disponibilizados no formato: videoaula
                      gravada. É necessário disponibilizar internet para acessar à
                      videoaula, não sendo possível realizar download para assistir
                      offline.
                    </li>
                    <li>
                      Guia Pareto: apostila digital de tamanho variado contendo
                      exposição teórica do conteúdo do tema e baseada nas principais
                      literaturas de cada grande área. Pode ser impressa pelo aluno.
                    </li>
                    <li>
                      Flashcards: são os baralhos autorais compostos por perguntas e
                      respostas, com quantidade variada, seguindo o conteúdo abordado no
                      Guia Pareto e contendo uma autoavaliação do acerto pelo
                      usuário no ato de realização do flashcard
                    </li>
                    <li>
                      Slides: os slides são agrupados em documento único em
                      formato PDF e disponibilizados no mesmo campo destinado
                      aos mapas mentais. São os mesmos slides que o professor
                      usa durante a aula gravada. Podem ser impressos pelo aluno.
                    </li>
                  </ul>
                </li>
                <li>
                  Suporte
                  <ul style={{ marginLeft: 48 }}>
                    <li>
                      Suporte de conteúdo: é realizado por meio do fórum presente na
                      plataforma, com possibilidade de escrever dúvida em questões e
                      nos conteúdos teóricos, obtendo resposta por equipe técnica de
                      médicos e plantonistas internos de Medicina, com prazo de 48
                      horas úteis.
                    </li>
                    <li>
                      Suporte de dúvidas gerais: é realizado por meio do WhatsApp,
                      por conta comercial e pelo mentor do aluno, pessoa destinada
                      a manter o contato direto com o aluno e solucionar as
                      dúvidas sobre usabilidade, cronogramas do curso e metodologia.
                    </li>
                  </ul>
                </li>
              </ol>
              <Text as="h1" fontWeight="bold">
                5. REMUNERAÇÃO
              </Text>
              <Text>
                Na contratação do serviço prestado, a plataforma poderá solicitar
                as informações financeiras do usuário, como CPF, endereço de
                cobrança e dados de cartões. Ao inserir os referidos dados,
                o usuário concorda que sejam cobrados, de acordo com a forma
                de pagamento que venha a ser escolhida, os preços então vigentes
                e informados no momento da contratação. Referidos dados
                financeiros poderão ser armazenados para facilitar acessos e
                contratações futuras, sendo essa a finalidade precípua.
              </Text>
              <Text>
                O pagamento somente será concretizado quando assim for validado pela
                PLATAFORMA.
              </Text>
              <Text>
                As informações específicas quanto a valores devidos e
                parcelamentos estão explicitamente apontados na plataforma,
                de forma que o USUÁRIO tenha total acesso e conhecimento acerca
                das suas obrigações quanto à remuneração dos cursos ofertados.
              </Text>
              <Text>
                A Aristo utiliza os serviços de prestadores terceirizados para
                a finalização dos pagamentos, sendo que essas empresas são
                responsáveis por todo o processamento dos dados pessoais captados
                para este fim, sendo de responsabilidade do usuário ofertar os dados
                corretos e das referidas plataformas zelar pela segurança dos mesmos.
              </Text>

              <Text as="h1" fontWeight="bold">
                6. DAS RESPONSABILIDADES
              </Text>
              <Text>
                É de responsabilidade do usuário:
              </Text>
              <ul>
                <li>
                  A correta utilização da plataforma e dos serviços ofertados,
                  prezando pela boa convivência, pelo respeito e cordialidade
                  entre os demais usuários.
                </li>
                <li>
                  O cumprimento e o respeito ao conjunto de regras disposto
                  neste Termo Geral de Condições de Uso, na respectiva Política
                  de Privacidade e na legislação nacional e internacional.
                </li>
                <li>
                  A proteção aos dados de acesso à sua conta/perfil (login e senha).
                </li>
              </ul>
              <Text>
                É de responsabilidade da plataforma:
              </Text>
              <ul>
                <li>
                  Indicar as características dos serviços oferecidos, sem se
                  responsabilizar pelo modo e tempo da execução dos cursos,
                  bem como sem qualquer compromisso quanto ao resultado final
                  das provas de Residência médica prestada pelos USUÁRIOS.
                </li>
                <li>
                  Preservar as informações que foram por ela divulgadas,
                  sendo que os comentários ou informações divulgadas por
                  usuários são de inteira responsabilidade dos próprios usuários.
                </li>
                <li>
                  Supervisionar os conteúdos ou atividades ilícitas praticadas
                  através da sua plataforma.
                </li>
              </ul>
              <Text>
                A plataforma não se responsabiliza por links externos contidos em seu
                sistema que possam redirecionar o usuário ao ambiente externo à sua
                rede.
              </Text>
              <Text>
                Não poderão ser incluídos links externos ou páginas que sirvam para
                fins comerciais ou publicitários ou quaisquer informações ilícitas,
                violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias
                ou ofensivas.
              </Text>
              <Text as="h1" fontWeight="bold">
                7. PRIVACIDADE E TRATAMENTO DOS DADOS
              </Text>
              <Text as="h2" fontWeight="bold">
                7.1 .Consentimento
              </Text>
              <Text>
                É a partir do seu consentimento que tratamos os seus dados
                pessoais. O consentimento é a manifestação livre, informada
                e inequívoca pela qual o cliente autoriza a  tratar seus dados.
              </Text>
              <Text>
                Assim, em consonância com a Lei Geral de Proteção de Dados,
                seus dados só serão coletados, tratados e armazenados
                mediante prévio e expresso consentimento.
              </Text>
              <Text>
                O consentimento do cliente será obtido de forma específica para
                cada finalidade acima descrita, evidenciando o compromisso de
                transparência e boa-fé com os seus usuários/clientes,
                seguindo as regulações legislativas pertinentes.
              </Text>
              <Text>
                Ao utilizar os serviços da ARISTO e fornecer seus dados pessoais,
                você está ciente e consentindo com as disposições desta Política
                de Privacidade, além de conhecer seus direitos e saber como
                exercê-los.
              </Text>
              <Text>
                A qualquer tempo e sem nenhum custo você poderá revogar o seu consentimento.
              </Text>
              <Text>
                É importante destacar que a revogação do consentimento para o
                tratamento dos dados pode implicar a impossibilidade da performance
                adequada de alguma funcionalidade do programa que dependa da operação.
                Tais consequências serão informadas previamente.
              </Text>
              <Text as="h2" fontWeight="bold">
                7.2. Quais os direitos do USUÁRIO?
              </Text>
              <Text>
                A ARISTO assegura a seus usuários/clientes os seus direitos de
                titular, previstos no artigo 18 da Lei Geral de Proteção de Dados.
                Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
              </Text>
              <Text>
                Confirmar a existência de tratamento de dados, de maneira simplificada
                ou em formato claro e completo.
              </Text>
              <Text>
                Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma
                impressa ou por meio eletrônico, seguro e idôneo.
              </Text>
              <Text>
                Corrigir seus dados ao solicitar a edição, correção ou atualização destes.
              </Text>
              <Text>
                Limitar seus dados quando estiverem sendo desnecessários, excessivos
                ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.
              </Text>
              <Text>
                Solicitar a portabilidade de seus dados por meio de um relatório de dados cadastrais que a
                ARISTO trata a seu respeito.
              </Text>
              <Text>
                Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.
              </Text>
              <Text>
                Revogar seu consentimento, desautorizando o tratamento de seus dados.
              </Text>
              <Text>
                Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as
                consequências da negativa.
              </Text>
              <Text as="h2" fontWeight="bold">
                7.3. Como o cliente poderá exercer seus direitos de titular?
              </Text>
              <Text>
                Para exercer os seus direitos de titular, o cliente deve entrar em contato com a
                ARISTO através do Canal de Suporte.
              </Text>
              <Text as="h2" fontWeight="bold">
                7.4. Como e por quanto tempo os dados do cliente serão armazenados?
              </Text>
              <Text>
                Os dados pessoais coletados pela ARISTO serão utilizados e armazenados
                durante o tempo necessário para a prestação do serviço ou para que as
                finalidades elencadas na presente Política de Privacidade sejam
                atingidas, considerando os direitos dos titulares dos dados e dos
                controladores.
              </Text>
              <Text>
                De modo geral, seus dados serão mantidos enquanto a relação contratual
                entre o USUÁRIO e a ARISTO perdurar.
              </Text>
              <Text>
                Findado o período de armazenamento dos dados pessoais,
                estes serão excluídos de nossas bases de dados ou anonimizados,
                ressalvadas as hipóteses legalmente previstas no artigo 16
                da Lei Geral de Proteção de Dados, a saber:
              </Text>
              <div style={{ paddingLeft: 48 }}>
                <Text>
                  I - Cumprimento de obrigação legal ou regulatória pelo controlador;
                </Text>
                <Text>
                  II – estudo por órgão de pesquisa, garantida, sempre que possível,
                  a anonimização dos dados pessoais;
                </Text>
                <Text>
                  III – transferência a terceiro, desde que respeitados os requisitos de
                  tratamento de dados dispostos nesta Lei; ou
                </Text>
                <Text>
                  IV – uso exclusivo do controlador, vedado seu acesso por terceiro,
                  e desde que anonimizados os dados.
                </Text>
              </div>
              <Text>
                Isto é, informações pessoais sobre você que sejam imprescindíveis
                para o cumprimento de determinações legais, judiciais e
                administrativas e/ou para o exercício do direito de defesa em
                processos judiciais e administrativos serão mantidas, a
                despeito da exclusão dos demais dados.
              </Text>
              <Text>
                O armazenamento de dados coletados pela ARISTO reflete o nosso
                compromisso com a segurança e a privacidade dos seus dados.
                Empregamos medidas e soluções técnicas de proteção aptas a
                garantir a confidencialidade, a integridade e a inviolabilidade
                dos seus dados. Além disso, também contamos com medidas de
                segurança apropriadas contra riscos, e com controle de acesso
                às informações armazenadas.
              </Text>
              <Text as="h2" fontWeight="bold">
                7.5 O que fazemos para manter os dados dos clientes seguros?
              </Text>
              <Text>
                Para mantermos suas informações pessoais seguras,
                usamos ferramentas físicas, eletrônicas e gerenciais
                orientadas para a proteção da sua privacidade.
              </Text>
              <Text>
                Aplicamos essas ferramentas considerando a natureza dos dados
                pessoais coletados, o contexto e a finalidade do tratamento e
                os riscos que eventuais violações geram para os direitos e
                liberdades do titular dos dados coletados e tratados.
              </Text>
              <Text>
                Entre as medidas que adotamos, destacamos as seguintes:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  Apenas pessoas autorizadas têm acesso a seus dados pessoais.
                </li>
                <li>
                  O acesso a seus dados pessoais é feito somente após o compromisso
                  de confidencialidade.
                </li>
                <li>
                  Seus dados pessoais são armazenados em ambiente seguro e idôneo.
                </li>
              </ul>
              <Text>
                A  ARISTO se compromete a adotar as melhores posturas para evitar
                incidentes de segurança. Contudo, é necessário destacar que nenhuma
                página virtual é inteiramente segura e livre de riscos. É possível
                que, apesar de todos os nossos protocolos de segurança,
                problemas de culpa exclusivamente de terceiros ocorram, como
                ataques cibernéticos de hackers ou, também, em decorrência da
                negligência ou imprudência do próprio usuário/cliente.
              </Text>
              <Text>
                Em caso de incidentes de segurança que possam gerar risco ou
                dano relevante para você ou qualquer um de nossos
                usuários/clientes, comunicaremos aos afetados e à
                Autoridade Nacional de Proteção de Dados sobre o ocorrido,
                em consonância com as disposições da Lei Geral de Proteção
                de Dados.
              </Text>
              <Text as="h2" fontWeight="bold">
                7.6 Com quem os dados dos clientes podem ser compartilhados?
              </Text>
              <Text>
                Tendo em vista a preservação da privacidade dos clientes,
                a ARISTO não compartilhará seus dados pessoais com nenhum
                terceiro não autorizado.
              </Text>
              <Text>
                Seus dados poderão ser compartilhados com nossos parceiros
                comerciais. Estes recebem seus dados apenas quando necessário
                para a prestação dos serviços contratados, e os nossos contratos
                são orientados pelas normas de proteção de dados do ordenamento
                jurídico brasileiro.
              </Text>
              <Text>
                Todavia, nossos parceiros têm suas próprias Políticas de Privacidade,
                que podem divergir desta.
              </Text>
              <Text>
                Além disso, também existem outras hipóteses em
                que seus dados poderão ser compartilhados, que são:
              </Text>
              <div style={{ marginLeft: 48 }}>
                <Text>
                  I – Determinação legal, requerimento, requisição ou ordem judicial,
                  com autoridades judiciais, administrativas ou governamentais
                  competentes.
                </Text>
                <Text>
                  II – Caso de movimentações societárias, como fusão,
                  aquisição e incorporação, de forma automática.
                </Text>
                <Text>
                  III – Proteção dos direitos da Aristo em qualquer tipo de conflito,
                  inclusive os de teor judicial.
                </Text>
              </div>
              <Text>
                Seguem situações em que pode haver o compartilhamento de informações com
                parceiros autorizados:
              </Text>
              <Text as="h3" fontStyle="italic">
                7.6.1 Cumprimento dos processos envolvidos na venda do produto
                ou prestação de serviço
              </Text>
              <Text>
                Seus dados pessoais podem ser enviados para terceiros com finalidade de:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  Análise de crédito e antifraude.
                </li>
                <li>
                  Processamento dos pagamentos de cartão de crédito.
                </li>
                <li>
                  Registro do boleto de cobrança.
                </li>
                <li>
                  Entrega de produtos e serviços digitais em plataformas de terceiros.
                </li>
              </ul>
              <Text as="h3" fontStyle="italic">
                7.6.2 Cumprimento de obrigações legais
              </Text>
              <Text>
                Seus dados pessoais são estritamente necessários para o cumprimento de obrigações legais,
                que podem ser enviados para terceiros com finalidade de:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  Emissão de nota fiscal de venda de produtos e serviços.
                </li>
                <li>
                  Atender obrigações legais federal, estaduais, municipais.
                </li>
                <li>
                  Atender fiscalizações da Agência Nacional de Proteção de Dados - ANPD.
                </li>
                <li>
                  Atender fiscalizações de governos federal, estaduais e municipais.
                </li>
              </ul>
              <Text as="h3" fontStyle="italic">
                7.6.3 Marketing
              </Text>
              <Text>
                Alguns de seus dados pessoais poderão ser enviados para parceiros
                com finalidade de:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  Divulgação dos nossos produtos em outras plataformas e mídias sociais.
                </li>
                <li>
                  Divulgação dos nossos produtos em anúncios em outros portais de conteúdo.
                </li>
                <li>
                  Personalização da oferta de produtos em nosso site.
                </li>
                <li>
                  Personalização de campanhas de marketing em nosso site.
                </li>
              </ul>
              <Text>
                Dados completos de contato, endereço de cobrança e dados de pagamento não são
                enviados para terceiros para ações de marketing.
              </Text>
              <Text as="h3" fontStyle="italic">
                7.6.4 Estatísticas
              </Text>
              <Text>
                Alguns de seus dados pessoais, principalmente os de pedido,
                pagamento, endereço de entrega/cobrança e navegação,
                mas não restritos a esses, poderão ser utilizados de forma
                anonimizada com a finalidade de:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  Extrair estatísticas gerais de navegação do site.
                </li>
                <li>
                  Extrair estatísticas gerais de conversão do site.
                </li>
                <li>
                  Analisar comportamentos de navegação e estatísticas de uso do site.
                </li>
              </ul>
              <Text as="h1" fontWeight="bold">
                8. COOKIES OU DADOS DE NAVEGAÇÃO
              </Text>
              <Text>
                A  ARISTO faz uso de cookies, que são arquivos de texto enviados
                pela plataforma para o seu computador, sendo ali armazenados,
                contendo informações relacionadas à navegação do site.
                Em suma, os cookies são utilizados para aprimorar a
                experiência de uso.
              </Text>
              <Text>
                Ao acessar a nossa plataforma e consentir com o uso de cookies,
                você manifesta conhecer e aceitar a utilização de um
                sistema de coleta de dados de navegação com o uso desse
                recurso em seu dispositivo.
              </Text>
              <Text>
                Você pode, a qualquer tempo e sem nenhum custo,
                alterar as permissões, bloquear ou recusar os cookies.
                Todavia, a revogação do consentimento de determinados
                cookies pode inviabilizar o funcionamento correto de
                alguns recursos da plataforma.
              </Text>

              <Text as="h1" fontWeight="bold">
                9. ALTERAÇÃO DESTA POLÍTICA DE PRIVACIDADE E TERMOS DE USO
              </Text>
              <Text>
                Reservamos o direito de modificar esta Política de Privacidade e
                Termos de Uso a qualquer momento, principalmente em função da
                adequação a eventuais alterações feitas em nossa plataforma ou
                em âmbito legislativo. Recomendamos que você a revise com
                frequência.
              </Text>
              <Text>
                Eventuais alterações entrarão em vigor a partir da publicação
                em nossa plataforma e sempre lhe notificaremos acerca
                das mudanças ocorridas.
              </Text>
              <Text>
                Ao utilizar os nossos serviços e fornecer os seus dados pessoais
                após tais modificações, você as consentirá.
              </Text>

              <Text as="h1" fontWeight="bold">
                10. RESPONSABILIDADE NO TRATAMENTO DE DADOS
              </Text>
              <Text>
                A ARISTO prevê a responsabilidade dos agentes que atuam nos
                processos de tratamento de dados, em conformidade com
                os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
              </Text>
              <Text>
                Nos comprometemos em manter esta Política de Privacidade atualizada,
                observando suas disposições e zelando por seu cumprimento.
              </Text>
              <Text>
                Além disso, também assumimos o compromisso de buscar condições
                técnicas e organizativas seguramente aptas a proteger todo
                o processo de tratamento de dados.
              </Text>
              <Text>
                Caso a Autoridade Nacional de Proteção de Dados exija a
                doção de providências em relação ao tratamento de dados
                realizado pela ARISTO, comprometemo-nos a segui-las.
              </Text>

              <Text as="h1" fontWeight="bold">
                11. ISENÇÃO DE RESPONSABILIDADE
              </Text>
              <Text>
                Embora a plataforma adote elevados padrões de segurança a fim
                de evitar incidentes, não há nenhuma página virtual
                inteiramente livre de riscos. Nesse sentido, a ARISTO não
                se responsabiliza por:
              </Text>
              <Text>
                I – Quaisquer consequências decorrentes da negligência,
                imprudência ou imperícia dos usuários em relação a seus dados
                individuais. Garantimos e nos responsabilizamos apenas pela
                segurança dos processos de tratamento de dados e do cumprimento
                das finalidades descritas no presente instrumento.
                Destacamos que a responsabilidade em relação à confidencialidade
                dos dados de acesso é do usuário.
              </Text>
              <Text>
                II – Ações maliciosas de terceiros, como ataques de
                hackers, exceto se comprovada conduta culposa ou
                deliberada da ARISTO.
                Destacamos que em caso de incidentes de segurança que possam
                gerar risco ou dano relevante para você ou qualquer um de
                nossos usuários/clientes, comunicaremos aos afetados e à
                Autoridade Nacional de Proteção de Dados sobre o ocorrido
                e cumpriremos as providências necessárias.
              </Text>
              <Text>
                III – Inveracidade das informações inseridas pelo usuário/cliente
                nos registros necessários para a utilização dos serviços da
                ARISTO; quaisquer consequências decorrentes de informações
                falsas ou inseridas de má-fé são de inteira responsabilidade
                do usuário/cliente.
              </Text>

              <Text as="h1" fontWeight="bold">
                12. ENCARREGADO DE PROTEÇÃO DE DADOS
              </Text>
              <Text>
                A  ARISTO disponibiliza os seguintes meios para que você possa
                entrar em contato conosco para exercer os seus direitos de titular:
              </Text>
              <Text>
                WhatsApp: (21) 99699-8020.
              </Text>
              <Text>
                E-mail: equipe@aristo.com.br e juridico@aristo.com.br.
              </Text>

              <Text as="h1" fontWeight="bold">
                13. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL
              </Text>
              <Text>
                Os documentos, conteúdos e criações contidos neste Programa
                pertencem aos seus criadores e colaboradores. A autoria dos
                conteúdos, materiais e imagens exibidos na ARISTO são
                protegidos por leis nacionais e internacionais. Dessa forma,
                eles não podem ser copiados, reproduzidos, modificados,
                publicados, atualizados, postados, transmitidos ou distribuídos
                de qualquer maneira sem autorização prévia e por escrito da
                ARISTO, cumprindo-se o que preceitua a legislação nacional.
              </Text>

              <Text as="h1" fontWeight="bold">
                14. CONDIÇÕES GERAIS
              </Text>
              <Text>
                Os clientes poderão utilizar os serviços da ARISTO apenas para
                finalidades lícitas. Dentro do programa não será admitido publicar,
                enviar, distribuir ou divulgar conteúdos ou informações de caráter
                difamatório, obsceno ou ilícito, inclusive informações de
                propriedade exclusiva pertencentes a outras pessoas ou empresas,
                bem como marcas registradas ou informações protegidas por
                direitos autorais, sem a expressa autorização do detentor
                desses direitos.
              </Text>
              <Text>
                Sem prejuízo de outras regras previstas nestes Termos de Uso e
                Política de Privacidade, o usuário se compromete a não realizar
                as ações abaixo informadas, SOB PENA DE EXCLUSÃO SUMÁRIA DO
                PROGRAMA:
              </Text>
              <ul style={{ marginLeft: 48 }}>
                <li>
                  copiar, modificar, reproduzir, distribuir, fazer download,
                  armazenar, transmitir, vender, revender, fazer engenharia
                  reversa ou criar produtos derivados de qualquer conteúdo
                  disponível no programa;
                </li>
                <li>
                  violar ou tentar violar quaisquer medidas de segurança do
                  programa, ou tirar proveito de qualquer inconsistência
                  do sistema para obter vantagem para si próprio ou
                  para terceiros, ou causar prejuízo à ARISTO e/ou a
                  terceiros, especialmente ao acessar ou tentar acessar
                  qualquer conta de clientes, ou ainda, ao obter e divulgar
                  a senha de assinante;
                </li>
                <li>
                  submeter à plataforma qualquer informação falsa, imprecisa ou incompleta;
                </li>
                <li>
                  manipular preços de conteúdo;
                </li>
                <li>
                  interferir nas transações realizadas entre outros clientes;
                </li>
                <li>
                  passar-se por outra pessoa ou organização;
                </li>
                <li>
                  usar SPAM ou qualquer outra prática de divulgação ou captação
                  de informação inapropriada, caluniosa ou ilegal para
                  promover qualquer conteúdo inserido no Programa;
                </li>
                <li>
                  utilizar de forma inadequada ou não autorizada a marca ARISTO em anúncios e comunicações;
                </li>
                <li>
                  promover divulgações do tipo SPAM  para lista de contatos genérica,
                  ou ainda, encaminhar reiteradamente e sem consentimento mensagens,
                  comentários a grupos indefinidos, sem qualquer anuência dos mesmos;
                </li>
                <li>
                  realizar publicações e anúncios de produtos que utilizem indevidamente a
                  marca ARISTO, promovendo confusão junto a terceiros, de forma a acreditar-se
                  que as mencionadas publicações contam com a autorização da ARISTO;
                </li>
                <li>
                  promover campanhas e anúncios com promessa e garantias inescrupulosas e ilegais,
                  com o objetivo de auferir vantagem indevida;
                </li>
                <li>
                  realizar divulgações que ofereçam como único e principal benefício na compra de determinado
                  produto a oportunidade de ser afiliado do mesmo.
                </li>
              </ul>

              <Text as="h1" fontWeight="bold">
                15. INTERRUPÇÃO E CANCELAMENTO DOS SERVIÇOS
              </Text>
              <Text>
                A ARISTO poderá interromper o fornecimento de qualquer serviço,
                a qualquer momento, sem prévia notificação, desde que haja
                motivo razoável e embasamento legal para tanto.
              </Text>
              <Text>
                A ARISTO não se responsabiliza por qualquer prejuízo decorrente da utilização de seus serviços,
                exceto quando explicitamente mencionado em contrário.
              </Text>
              <Text>
                O usuário entende que é inerente às atividades online interrupções
                e eventuais falhas de desempenho ou segurança.
                Desta forma, a ARISTO não poderá ser responsabilizada
                por falhas decorrentes de erros técnicos, falhas de
                segurança ou invasão por parte de terceiros.
              </Text>

              <Text as="h1" fontWeight="bold">
                16. PENALIDADES
              </Text>
              <Text>
                Qualquer conduta contrária ao previsto nestes Termos de Uso e
                Políticas de Privacidade poderá ser punida:
              </Text>
              <ul>
                <li>
                  Com a suspensão imediata do acesso aos conteúdos, sem prejuízo de serem tomadas as ações
                  legais cabíveis pela própria empresa ou por terceiros.
                </li>
              </ul>
              <Text>
                A ARISTO, seus parceiros comerciais ou quaisquer terceiros
                prejudicados poderão tomar as ações legais cabíveis em
                decorrência dos delitos ou contravenções de que sejam vítimas,
                ou, ainda, buscar o ressarcimento pelos prejuízos civis por
                descumprimento dos Termos de Uso e Políticas de Privacidade,
                sem que isso acarrete qualquer direito de indenização por
                parte da ARISTO ao usuário e prestador de serviços quando
                ocorrem reiteradas suspeitas de fraudes. Assim procederá
                em relação aos usuários:
              </Text>
              <ul>
                <li>
                  Suspensão imediata da conta para apuração da suspeita com comunicação oficial da apuração.
                </li>
                <li>
                  Se confirmado, formação de dossiê para processamento jurídico e exclusão do usuário da plataforma.
                </li>
                <li>
                  Reembolso dos valores à parte lesada.
                </li>
              </ul>

              <Text as="h1" fontWeight="bold">
                17. USO ÉTICO, SEGURO E LEGAL
              </Text>
              <Text>
                Ser ético significa agir de acordo com as regras que orientam a
                sociedade, portanto, os usuários devem sempre respeitar as
                condições que regem a utilização dos serviços e benefícios
                do Programa, sob pena de indenizar a quem causar dano e de
                ter sua conta de acesso excluída.
              </Text>
              <Text>
                Ao fornecer dados e informações, os clientes se obrigam a fazê-lo
                sempre com compromisso de veracidade e autenticidade.
              </Text>
              <Text>
                Os usuários devem utilizar os recursos da plataforma para a finalidade para qual esta foi constituída.
              </Text>
              <Text>
                Os usuários  devem manter sigilo dos dados de sua conta de acesso,
                sua senha e demais dispositivos de autenticação em relação a terceiros,
                não os divulgando ou compartilhando com quem quer que seja.
              </Text>
              <Text>
                Caso os usuários e prestadores de serviços suspeitem que a
                confidencialidade da senha foi quebrada ou que houve o
                comprometimento de algum dispositivo de autenticação, devem
                proceder sua troca ou atualização o mais rápido possível.
                Se não conseguirem acessar a Internet para obter suporte,
                deverá buscar atendimento dentre os canais disponíveis.
              </Text>
              <Text>
                Ao aderir à plataforma ARISTO, você aceita guiar-se pelos
                Termos e Condições de Uso e pela Política de Privacidade que
                se encontram vigentes nesta data.
              </Text>

              <Text as="h1" fontWeight="bold">
                18. DISPOSIÇÕES GERAIS
              </Text>
              <Text>
                A tolerância do eventual descumprimento de quaisquer cláusulas
                e condições do presente instrumento não constituirá novação
                das obrigações aqui estipuladas e tampouco impedirá ou
                inibirá a exigibilidade das mesmas a qualquer tempo.
              </Text>

              <Text as="h1" fontWeight="bold">
                19. LEI APLICÁVEL E JURISDIÇÃO
              </Text>
              <Text>
                Os Termos e Condições de Uso aqui descritos são interpretados
                segundo a legislação brasileira, no idioma português,
                sendo eleito o Foro da Comarca do Rio de Janeiro-RJ para
                dirimir qualquer litígio, questão ou dúvida superveniente,
                com expressa renúncia de qualquer outro, por mais privilegiado
                que seja, restando o endereço da PLATAFORMA assim consignado:
                ARISTO - Rua Lauro Müller, 116 - Botafogo - Rio de Janeiro - RJ -
                Sala 3107 (31º andar), CEP 22290-906.
              </Text>

              <Text as="h1" fontWeight="bold">
                20. DÚVIDAS
              </Text>
              <Text>
                Quaisquer dúvidas quanto ao conteúdo do presente Termo
                podem ser encaminhadas para os seguintes canais:
              </Text>
              <Text>
                WhatsApp: (21) 99699-8020.
              </Text>
              <Text>
                E-mail: equipe@aristo.com.br.
              </Text>
              <Text>
                O Usuário, titular dos dados, poderá solicitar ao Grupo ARISTO algumas
                ações referentes ao tratamento dos seus
                dados pessoais pelo e-mail da equipe, conforme determina a LGPD.
              </Text>
              <Text as="h2" fontWeight="bold">
                20.1 Solicitação e revisão de dados
              </Text>
              <Text>
                Você, o titular dos dados, poderá solicitar ao Grupo ARISTO o
                acesso aos seus dados pessoais armazenados, pelo e-mail
                indicado acima, até o momento do registro formal da solicitação
                ou revisão de dados incompletos, ou que necessitem de
                retificação que você próprio não consiga realizar.
              </Text>

              <Text as="h2" fontWeight="bold">
                20.2 Exclusão de dados
              </Text>
              <Text>
                O Grupo ARISTO reserva o direito de excluir apenas os
                dados não relacionados a obrigações legais e cumprimento
                de venda de produto e prestação de serviços em curso e
                vigentes. Caso você não tenha cadastro no nosso site e
                queira apagar os cookies instalados, basta limpar os
                cookies relacionados ao domínio do site e desabilitar
                a utilização de cookies nas configurações do seu navegador,
                sem nenhuma intervenção do Grupo ARISTO.
              </Text>

              <Text as="h2" fontWeight="bold">
                20.3 Prazos de atendimento de solicitação, revisão e exclusão de dados
              </Text>
              <Text>
                O Grupo ARISTO atenderá as solicitações nos prazos previstos pela LGPD,
                atualmente de 15 (quinze) dias, e com o prazo de início contando a
                partir do registro da solicitação formal pelo e-mail indicado acima.
              </Text>
              <Text as="h2" fontWeight="bold">
                20.4 Segurança dos dados
              </Text>
              <Text>
                Não existe sistema de segurança infalível, e o Grupo ARISTO se responsabiliza
                apenas por danos e/ou prejuízos causados por dolo ou culpa.
              </Text>
              <Text as="h2" fontWeight="bold">
                20.5 Senhas
              </Text>
              <Text>
                A sua senha de login é armazenada com criptografia forte. Contudo, é essencial
                que você não compartilhe sua senha com mais ninguém e que ela seja
                uma senha minimamente complexa para evitar tentativas de invasão.
              </Text>
              <Text>
                O Grupo ARISTO não envia solicitações requisitando sua senha, seja por e-mail,
                telefone ou serviços de mensagens.
              </Text>
              <Text as="h2" fontWeight="bold">
                20.6 E-mails enviados
              </Text>
              <Text>
                O Grupo ARISTO envia e-mails relacionados ao cumprimento dos processos de venda
                de produtos, prestação de serviço e marketing. Esses e-mails estarão
                relacionados aos domínios “@aristo.com.br” e &quot;@eduq.app&quot;.
              </Text>
              <Text>
                Para evitar problemas de comunicação, é importante configurar sua ferramenta de correio
                eletrônico para considerar esses dois domínios como confiáveis.
              </Text>
              <Text>
                Os e-mails enviados pelo Grupo ARISTO não possuem anexos,
                principalmente com extensões .exe, .bat, .scr e
                outras frequentemente utilizadas para introdução de vírus.
              </Text>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </VStack>
  );
};

export default RegisterForm;
